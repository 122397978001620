import React, { useContext } from "react";

import { useStaticQuery, graphql } from "gatsby";



import SEO from "../components/seo";
import Layout from "../components/layout";
import useSiteMetadata from "../utils/useSiteMetadata"
// import "../components/layout.css"

// import Signup from "../components/signup";
// import Hero from "../components/basic-components/hero";
// import Tiles from "../components/tiles";
// import CustomCollectionsBar from "../components/custom-collections-bar";

import CTAImg from "../assets/img/Help-you-scaled-e1588324743844.jpg";

// import { AppContext, useGlobalContext } from "../utils/context"
import GetImgUrl, { abc } from "../utils/customFunctions";
// import SubheadingText from "../components/basic-components/subheading-text";

import loadable from '@loadable/component'
// import Branches from "../components/branches-component";

const CustomCollectionsBar = loadable(() => import("../components/custom-collections-bar"))
const Branches = loadable(() => import("../components/branches-component"))
const Tiles = loadable(() => import("../components/tiles"))
const Hero = loadable(() => import("../components/basic-components/hero"))
const Signup = loadable(() => import("../components/signup"))
const SubheadingText = loadable(() => import("../components/basic-components/subheading-text"))
const QuoteCarousel = loadable(() => import("../components/quote-carousel"))

const IndexPage = () => {
  // const {getImgUrl} = useGlobalContext()
  // getImgUrl("asd")
  // console.log(GetImgUrl("a8026070-072a-586e-b423-2c9b261c378e"))

  const data = useStaticQuery(graphql`
    {
      allJrmProducts(limit: 5) { 
        nodes {
          Id
          Name
          AttractivePrice
          Description
          Slug
        }
      }

      strapiHomepage {
        SEOTitle
        SEODescription
        Canonical
        MetaTags{
          MetaName
          MetaContent
        }
        Header {
          Visible
          URL
          Title
          Subtitle
          CTA
          Subheading
          SubheadingSummary
          LeftBgColor
          RightBgColor
          Background {
            url
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1900) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          CTASecondary
          URLSecondary
        }
        CustomCollection {
          Title
          CustomCollectionTile {
            Title
            Slug
            Subtitle
            Link
            id
            Media {
              url
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1900) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            Item {
              id
              Reference
            }
          }
        }
        HomepageSections
      }

      strapiCollections {
        Title
        id
        CustomCollectionTile {
          Title
          Subtitle
          Link
          id
          Media {
            url
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1900) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          Item {
            id
            Reference
          }
        }
      }
    }
  `);

  var headerData = {
    headerTitle: data.strapiHomepage.Header.Title,
    headerDescription: data.strapiHomepage.Header.Subtitle,
    headerCTAPrimaryLabel: data.strapiHomepage.Header.CTA,
    headerCTAPrimaryUrl: data.strapiHomepage.Header.URL,
    headerCTASecondaryLabel: data.strapiHomepage.Header.CTASecondary,
    headerCTASecondaryUrl: data.strapiHomepage.Header.URLSecondary,
    headerBgImage:
      data.strapiHomepage.Header.Background.localFile.childImageSharp.fluid,
    headerRightBgColor: data.strapiHomepage.Header.RightBgColor ? data.strapiHomepage.Header.RightBgColor : '',
    headerLeftBgColor: data.strapiHomepage.Header.LeftBgColor ? data.strapiHomepage.Header.LeftBgColor : '',
  };

  var singleCTAData = [
    {
      Background: {
        publicURL: CTAImg,
      },
      Title: "Artisan craftmanship",
      url: "#0",
    },
  ];

  const branches = data.strapiHomepage.HomepageSections.filter((item) => item.BranchesList)
  const branchList = branches ? branches[0]?.BranchesList : []

  const { siteUrl: url } = useSiteMetadata();

  return (
    <div>
      <Layout
        pageData={headerData}
        headerShow={data.strapiHomepage.Header.Visible}
      >
        <SEO title={data.strapiHomepage.SEOTitle} description={data.strapiHomepage.SEODescription} 
             meta={data.strapiHomepage.MetaTags}
             canonical={data.strapiHomepage.Canonical ? data.strapiHomepage.Canonical : `${url}`}/>

        {/* <Tiles></Tiles> */}

        {/* <Carousel title={carouselContents.carouselTitle}></Carousel> */}
        {/* <Carousel title={"Memorials for all"} data={data.allJrmProducts.nodes} url="/collections"></Carousel> */}

        {/* <br/> */}
        <SubheadingText
          subheading={data.strapiHomepage.Header.Subheading}
          summary={data.strapiHomepage.Header.SubheadingSummary}
          endLine={false} />

        <CustomCollectionsBar
          title={data.strapiHomepage.CustomCollection.Title}
          data={data.strapiHomepage.CustomCollection}
          url="/collections"
        ></CustomCollectionsBar>

        {branchList ? <Branches branchList={branchList} /> : ''}



        {/* this is the collection bar on the homepage that comes from the user selection relation in the top right dropdown in strapi */}
        {/* <CustomCollectionsBar title={"Memorials for all"} data={data.strapiHomepage.CustomCollection} url="/collections"></CustomCollectionsBar> */}

        {/* <Tiles data={data.strapiHomepageCta.HomepageCTA}></Tiles> */}

        {/* <Tiles data={singleCTAData} singleFlag={true}></Tiles> */}

        {data.strapiHomepage.HomepageSections.map((item, index) => {
          return (
            <div key={index}>
              {item.CTA !== undefined && item.CTA.length > 1 ? (
                <Tiles data={item.CTA} width='half' key={index}></Tiles>
              ) : (
                ""
              )}

              {
                item.CTA !== undefined && item.CTA.length === 1 ? (
                  <Tiles data={item.CTA} key={index} singleFlag={true} width='full'></Tiles>
                ) : (
                  ""
                )
                // (item.CTA !== null && Object.keys(item.CTA).length === 1)?<Tiles data={item.CTA} singleFlag={true}></Tiles>:""
                //don't forget to fix this once the cta.length of a json (dynamic zone) is found
              }

              {item.Testimonial 
                  ? (<div className="">
                      <QuoteCarousel quoteList={item.Testimonial}></QuoteCarousel>
                    </div>
                    ) 
                  : (
                    ""
                  )}

              {item.Hero ? (
                <Hero
                  title={item.Hero.Title}
                  description={item.Hero.Description}
                  label={item.Hero.Label}
                  url={item.Hero.url}
                  key={index}
                ></Hero>
              ) : (
                ""
              )}

              {/* {
                item.YoutubeURL ? (
                  <VideoComponent  src={item.YoutubeURL}/>
                ) : ('')
              } */}
            </div>
          );
        })}

        {/* old hero kept for posterity. will be removed */}
        {/* <Hero 
        title={"Welcome to Mindful Memorials"}
        description={"Aute reprehenderit aliquip exercitation tempor enim aliqua dolore. Tempor nulla quis sunt laborum mollit ut sint do elit cillum. Eiusmod sint culpa labore minim nulla exercitation et aute excepteur ipsum qui aute exercitation quis."}
        label={"Find our more"}
        url={"/collections"}
        ></Hero> */}



        <Signup
          title="Sign Up to Our Newsletter"
          description="Get updates on all the lastest information, or stay informed about our courses"
          buttonLabel="Sign Up"
        />


      </Layout>
    </div>
  );
};

export default IndexPage;
